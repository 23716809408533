@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: Avenir LT Std;
  color: #333333;
}
.customone:checked {
  accent-color: #048759 !important;
  background-color: white !important;
}

.container {
  width: 100%;
  overflow: hidden;
  /* max-width: none !important; */
}

.checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-container input[type="checkbox"] {
  display: none;
}

.checkbox-container label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  border: 2px solid #73c7a9;
  border-radius: 3px;
  background-color: #fff;
}

.checkbox-container input[type="checkbox"]:checked + label::before {
  background-color: #73c7a9;
}

.checkbox-container input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: 5px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox-container label:hover::before,
.checkbox-container label:focus::before {
  border-color: #73c7a9;
}

/*Second*/
.checkbox-container1 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.checkbox-container1 input[type="checkbox"] {
  display: none;
}

.checkbox-container1 label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container1 label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin-top: -10px;
  width: 20px;
  height: 20px;
  border: 2px solid #73c7a9;
  border-radius: 3px;
  background-color: #fff;
}

.checkbox-container1 input[type="checkbox"]:checked + label::before {
  background-color: #32a885;
}

.checkbox-container1 input[type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  left: 7px;
  top: -8px;
  width: 7px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox-container1 label:hover::before,
.checkbox-container1 label:focus::before {
  border-color: #73c7a9;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
.sub_tab {
  /* border-bottom: none !important;
  flex-wrap: nowrap;
  overflow-x: scroll !important; */

  border-bottom: none !important;
  flex-wrap: nowrap;
  overflow-x: auto !important;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For IE 11 */
}
.sub_tab::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.sub_tab:hover::-webkit-scrollbar {
  width: auto;
}

.sub_tab a {
  color: #333333;
}
.nav-tabs .nav-link.active {
  background: #dbe9ed;
  /* opacity: 10%; */
  color: #73c7a9;
  border-radius: 30px;
}

.nav-tabs .nav-link {
  color: #333333;
  opacity: 70%;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;
  padding: 12px 10px 13px;
}
.tab-content {
  padding: 0;
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding: 0px;
  }
}

/*  merlin disable */

#merlin-chat {
  display: none !important;
}

.merlin {
  display: none !important;
}

#merlin-context-btn {
  display: none !important;
}
#merlin-preview-link {
  display: none !important;
}

.transition-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.transition-fixed {
  position: fixed;
  top: 0;
  transition: all 0.3s ease-in-out;
}

/* ril React Image lightbox styles ======================*/

@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==")
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+")
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7");
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=")
    no-repeat center;
}

.ril__zoomInButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+")
    no-repeat center;
}

.ril__zoomOutButton {
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=")
    no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.pagination {
  margin-top: 20px;
  margin-bottom: 20px;
}
.pagination ul {
  display: flex;
  justify-content: center;
  place-items: center;
  align-items: center;
}
.pagination ul li:hover {
  color: #333333;
}
.pagination ul li {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 5px 10px;
}
.pagination ul li.selected {
  border: 1px solid currentColor;
}

/* PAGER */
.datatable-pagination ul {
  margin: 0;
  padding-left: 0;
}

.datatable-pagination li {
  list-style: none;
  float: left;
}

.datatable-pagination li.datatable-hidden {
  visibility: hidden;
}

.datatable-pagination a {
  border: 1px solid transparent;
  float: left;
  margin-left: 2px;
  padding: 6px 12px;
  position: relative;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}

.datatable-pagination a:hover {
  background-color: #d9d9d9;
}

.datatable-pagination .datatable-active a,
.datatable-pagination .datatable-active a:focus,
.datatable-pagination .datatable-active a:hover {
  background-color: #d9d9d9;
  cursor: default;
}

.datatable-pagination .datatable-ellipsis a,
.datatable-pagination .datatable-disabled a,
.datatable-pagination .datatable-disabled a:focus,
.datatable-pagination .datatable-disabled a:hover {
  pointer-events: none;
  cursor: default;
}

.datatable-pagination .datatable-disabled a,
.datatable-pagination .datatable-disabled a:focus,
.datatable-pagination .datatable-disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.datatable-pagination .datatable-pagination a {
  font-weight: bold;
}

/* TABLE */
.datatable-table {
  max-width: 100%;
  width: 100%;
  border-spacing: 0;
  border-collapse: separate;
}

.datatable-table > tbody > tr > td,
.datatable-table > tbody > tr > th,
.datatable-table > tfoot > tr > td,
.datatable-table > tfoot > tr > th,
.datatable-table > thead > tr > td,
.datatable-table > thead > tr > th {
  vertical-align: top;
  padding: 8px 10px;
}

.datatable-table > thead > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: 1px solid #d9d9d9;
}

.datatable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-top: 1px solid #d9d9d9;
}

.datatable-table th {
  vertical-align: bottom;
  text-align: left;
}

.datatable-table th a {
  text-decoration: none;
  color: inherit;
}

.datatable-sorter,
.datatable-filter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

.datatable-sorter::before,
.datatable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.datatable-sorter::before {
  border-top: 4px solid #000;
  bottom: 0px;
}

.datatable-sorter::after {
  border-bottom: 4px solid #000;
  border-top: 4px solid transparent;
  top: 0px;
}

.datatable-ascending .datatable-sorter::after,
.datatable-descending .datatable-sorter::before,
.datatable-ascending .datatable-filter::after,
.datatable-descending .datatable-filter::before {
  opacity: 0.6;
}

.datatable-filter::before {
  content: "";
  position: absolute;
  right: 4px;
  opacity: 0.2;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-radius: 50%;
  border-top: 10px solid #000;
  top: 25%;
}

.datatable-filter-active .datatable-filter::before {
  opacity: 0.6;
}

.datatable-empty {
  text-align: center;
}

.datatable-top::after,
.datatable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

table.datatable-table:focus tr.datatable-cursor > td:first-child {
  border-left: 3px #73c7a9 solid;
}

table.datatable-table:focus {
  outline: solid 1px black;
  outline-offset: -1px;
}

.Toastify__toast-container {
  width: auto !important;
  max-width: 50% !important;
  min-width: 20rem !important;
}

.Toastify__toast {
  gap: 15px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker {
  display: flex;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker-popper {
  margin-top: 0 !important;
}
.react-datepicker__current-month {
  display: none !important;
}
.react-datepicker__month-dropdown-container {
  margin-right: 4px !important;
}
.react-datepicker__navigation-icon.react-datepicker__navigation-icon--previous {
  top: 4px !important;
}
.react-datepicker__navigation-icon.react-datepicker__navigation-icon--next {
  top: 4px !important;
}
.infinite-scroll-component__outerdiv {
  padding: 0 !important;
}
