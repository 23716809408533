input.jobtypedate {
  width: 100% !important;
  background: #73c7a9;
}

.ssu2_modal1_input input {
  height: auto !important;
}

.react-datepicker__input-container input {
  width: 100% !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}
