.headText {
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
}
.applicants_main {
  width: 100%;
  min-width: 1070px;
}
.overFlowX {
  overflow-x: auto;
}
.dropdown-content {
  min-width: 140px;
  min-height: 60px;
  text-align: center;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #73c7a9;
  background-color: white;
  margin-left: -30px !important;
}

.dropdown-content div:hover {
  background-color: #73c7a9;
}
.applicantsEachContent {
  height: 64px;
  line-height: 64px;
  width: 97%;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
  font-weight: 500;
}
.applicantsEach {
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 10px;
}
.applicantsContent {
  margin-top: -32px;
}
.applicantBadge {
  background-color: #73c7a9;
  color: #fff;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  margin-top: 17px;
}
.applicantClick {
  cursor: pointer;
}
