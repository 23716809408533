h3 {
  padding: 10px;
}

.dashboard_main {
  display: flex;
}

.Staff_hiring_Container {
  display: flex;
  justify-content: space-around;
}

/* .staff_contract {
  width: 50%;
  height: 98vh;
  background-color: white;
  border-radius: 6px;
  margin-top: 10px;
  margin-right: 10px;
} */
.staff_contract h3 {
  margin-bottom: 22px;
}

/* .card_body_1 { */
/* height: 130px; */
/* align-items: center; */
/* } */

.card_body_1 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 20px;
  line-height: 25px;
}

.card_body_2 {
  height: 135px;
  margin-top: 50px;
  align-items: center;
}

.card_body_2 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 16px;
  line-height: 19px;
}

.card_body_3 {
  display: flex;
  margin-top: 2px;
  height: 25vh;
  width: 19vw;
  align-items: center;
  margin-bottom: 10px;
}

.card_body_3 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 16px;
  line-height: 19px;
}

.bottom_card {
  margin: 30px 10px 0 10px;
}

.bottom_card hr {
  width: 1px;
  height: 60px;
  margin-left: 30px;
  display: inline-block;
  border: 1px rgba(0, 0, 0, 0.1);
}

.col_logo {
  float: left;
  margin-left: 40px;
}

.date_header {
  /* float: right; */
  margin: 20px 0px;
  margin-left: 875px;
}

/* .staff_hiring {
  width: 50%;
  height: 98vh;
  background-color: white;
  border-radius: 6px;
  margin-top: 10px;
  margin-left: 10px;
} */
