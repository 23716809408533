.logInLogo {
  width: 300px;
  height: 125px;
}

.logIn_txt1 {
  margin-top: 56px;
  font-size: 34px;
  line-height: 42px;
  text-align: center;
  color: #333333;

  @media (max-width: 640px) {
    margin-top: 45px;
    font-size: 32px;
    line-height: 40px;
  }
}

.logIn_txt2 {
  margin-top: 24px;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}

.logIn_body {
  width: 75%;
  margin: 60px auto 0px;

  @media (max-width: 640px) {
    margin: 40px auto 0px;
  }
}

.error-code {
  text-align: center;
  color: red;
  margin-top: 11px;
}

.login_letterBtn {
  margin-bottom: 20px;
}

.logIn_button {
  width: 100%;
  min-width: 200px;
  height: 48px;
  margin: 30px auto 0px;
  align-items: center !important;
  display: flex;
  margin: 0 auto;
  /* text-align: -webkit-right; */
  background-color: #73c7a9;
  justify-content: center;
  align-items: baseline;
  border-radius: 60px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  color: white;
  cursor: pointer;
  border: 1px solid #73c7a9;
}

.signUp_button {
  background-color: white;
  color: #73c7a9;
  border: 1px solid #73c7a9;
}

.link_button {
  width: 100%;
  height: 48px;
  margin: 30px auto 0px;
  color: #73c7a9;
  border-radius: 60px;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  padding-top: 16px;
  border: 1px solid #73c7a9;
  cursor: pointer;
}

.login_letterBtn {
  margin-top: 36px;
  font-size: 18px;
  line-height: 22px;
  color: #73c7a9;
  text-align: center;
  cursor: pointer;
}

.login_txt3 {
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
  color: #333333;
  text-align: center;
}

.forgotPassword_txt2 {
  width: 70%;
  margin: 24px auto 0px;
  max-width: 450px;
}

@media all and (max-width: 767px) {
  .logInLogo {
    width: 280px;
    height: 125px;
  }
}

.signUp_item_container {
  width: 100%;
  margin-right: 0px;
  margin-left: 0px;
  /* display: flex; */
  /* flex-direction: column; */
  /* justify-content: space-between; */
}

@media all and (max-width: 767px) {
  .signUp_item_container {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
  }

  .signUp_item {
    height: 100%;
    padding: 10px 10px;
  }
}

.signUp_item {
  width: 100%;
  display: flex;
  padding: 0px;
  float: left;
  fill: #333333;
  color: #333333;
  margin-bottom: 18px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 12px;
}

.signUp_item_selected {
  background-color: #73c7a9;
  color: white;
  fill: white;
}

.signUp_item_img {
  margin-top: 20px;
  margin-left: 10px;
}

.signUp_item_txt {
  width: 100%;
  text-align: center;
  padding: 10px;
}

.logIn_button_disabled {
  cursor: not-allowed;
}

@media all and (max-width: 686px) {
  .signUp_item {
    margin: 0px auto 18px;
  }
}

@media (max-width: 640px) {
  .logInLogo {
    width: 280px;
    height: 125px;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .signUp_item_img {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .signUp_item_txt {
    margin-top: 3px;
  }

  .signUp_item_img svg {
    width: 20px !important;
  }

  .signUp_item {
    height: auto;
  }
}
