.toast-success {
  color: #17b26a; /* White text color */
  background-color: #f6fef9;
  height: 20px !important;
  border-radius: 12px !important;
  min-height: 40px !important;
  box-shadow: none !important;
  border: 1px solid #75e0a7;
}

.toast-success .Toastify__close-button > svg {
  fill: #17b26a !important;
}

.Toastify__close-button {
  align-items: center !important;
  align-self: center !important;
}

.Toastify__toast-body {
  margin: 0 !important;
  /* height: 20px; */
  padding: 0;
}

.toast-warning {
  color: #fec84b; /* White text color */
  background-color: #fffcf5;
  height: 20px !important;
  border-radius: 12px !important;
  min-height: 40px !important;
  box-shadow: none !important;
  border: 1px solid #fec84b;
}

.toast-warning .Toastify__close-button > svg {
  fill: #fec84b !important;
}

/* Custom styles for error toast */
.toast-error {
  background-color: #ffd5d2;
  /* height: 20px !important; */
  border-radius: 12px !important;
  min-height: 40px !important;
  box-shadow: none !important;
  color: #e10d0d; /* White text color */
}

.toast-error .Toastify__close-button > svg {
  fill: #e10d0d !important;
}
