.ssu3_password {
  width: calc(100% - 110px);
  height: 46px;
  font-size: 16px;
  line-height: 18px;
  /* position: absolute; */
  /* left: 54px; */
  border-width: 0px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #00000033;
  width: 100%;
  padding: 13px 0px 13px 60px;
  border-radius: 100px;
}
/* .ssu2_modal3_select{
  border: 1px solid;
  width: 100%;
  padding: 10px;
  border-radius: 19px;
} */

.ssu2_modal3_select:focus {
  border: 1px solid #00000033;
  width: 100%;
  border-radius: 100px;
}

.ssu2_modal1_input {
  position: relative;
  flex-direction: column;
  /* margin-bottom: 20px; */
}
.ssu2_modal3_select {
  border: 1px solid #00000033;
  width: 100%;
  padding: 10px 10px 10px 60px;
  border-radius: 100px;
}

.ssu2_modal1_input input:focus {
  border: 1px solid black;
  box-shadow: 0px 0px 2px black;
}

.ssu3_eye {
  position: absolute;
  top: 17px;
  right: 30px;
  width: 18px;
  height: 15px;
  cursor: pointer;
}

.input_left_email_icon {
  /*** changed ***/
  position: absolute;
  top: 22px;
  left: 23px;
  width: 22px;
  height: 19px;
}

.input_left_icon {
  /*** changed ***/
  position: absolute;
  top: 21px;
  left: 23px;
  width: 22px;
  height: 19px;
}

.ssu3_body {
  width: 80%;
  /* max-width: 1000px; */
  height: auto;
  margin: 0px auto 0px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
