.staff_main {
  background-color: #fff;
  width: 100%;
  border-radius: 10px;
  padding: 30.4px 30.4px 50px 34.4px;
}
.staff_menu {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  display: flex;
  text-align: center;
  line-height: 48px;
}
.col-md-4 {
  cursor: pointer;
}
.activeMenu {
  background: #73c7a9;
  color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}
@media all and (max-width: 528px) {
  .staff_menu {
    display: block !important;
    border-radius: 23px !important;
  }
  .RSBtn {
    width: 100%;
  }
}
.line {
  width: 100%;
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  margin: 40px 0 40px 0;
}
.applicantJobTitle {
  background-color: #f8f8f8;
  display: flex;
  padding-top: 30px;
  padding-left: 12px;
}
