.theme2 {
  font-family: "Avenir LT Std";
  font-style: normal;
  color: #333333;
}

/** SIDE NAVIGATION **/
.theme2_side_nav {
  width: 280px;
  height: 100%;
  float: left;
  background-color: white;
  margin-top: 83px;
  z-index: 2;
  margin: 0px auto;
  background: #fff;
  height: 100%;
  padding: 16px 19px;
  border-radius: 15px;
}

.theme2_logo_container {
  padding: 20px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.theme2_letter_logo {
  width: 184px;
  height: auto;
  margin-left: 13px;
}

.theme2_nav_item {
  padding: 16px 28px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.theme2_nav_text {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-left: 17px;
  margin-left: 10px;
}

/***** CONTAINER *****/
.theme2_container {
  width: calc(100% - 5px);
  min-height: auto !important;
  float: left;
  z-index: 1;
}

/***** HEADER *****/
.theme2_header {
  width: 100%;
  height: 80px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  padding: 20px 24px;
}

.theme2_header_item {
  width: fit-content;
  height: 40px;
  float: right;
}

.theme2_header_search_container {
  float: right;
  margin-right: 25px;
  width: calc(100% - 140px);
  max-width: 250px;
  height: 40px;
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  padding: 8px 16px;
  background-color: white;
  position: relative;
}

.theme2_header_search_img {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  float: left;
}

.theme2_header_search {
  height: 17px;
  font-size: 17px;
  width: calc(100% - 30px);
  margin-left: 10px;
  float: left;
  border-width: 0px;
}

.theme2_header_avatar_container {
  width: auto;
  float: right;
  position: relative;
  cursor: pointer;
}

.theme2_header_avatar_name {
  float: right;
  margin-right: 14px;
  font-size: 20px;
  font-weight: 400;
}

.theme2_header_avatar_img {
  float: right;
  margin-top: -5px;
  width: 40px;
  height: 40px;
}

.theme2_header_badge {
  width: 13px;
  height: 13px;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/***** MAIN CONTAINER *****/
.theme2_main_container {
  width: 100%;
  /* min-height: calc(65vh - 60px); */
  /* padding: 18px 50px; */
  background: #f8f8f8;
}

.theme2_body {
  width: 100%;
  height: auto;

  min-height: 568px;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  /* padding: 24px 32px; */
}

/******************************
********* TABLET VIEW *********
******************************/
@media all and (max-width: 1024px) {
  .theme2_side_nav {
    width: 80px;
  }

  .theme2_nav_text {
    display: none;
    margin-left: 10px;
  }

  .theme2_letter_logo {
    display: none;
  }

  .theme2_container {
    width: calc(100% - 80px);
  }

  .theme2_main_container {
    padding: 10px;
  }

  .theme2_body {
    min-height: calc(100vh - 80px);
  }
}

/******************************
********* MOBILE VIEW *********
******************************/
@media all and (max-width: 825px) {
  /* .theme2_header_item {
        width: calc(100% - 300px);
    } */
  .theme2_header_avatar_name {
    display: block;
  }
  .theme2_header_search {
    margin-left: 6px;
    width: calc(100% - 27px);
  }
}

@media all and (max-width: 550px) {
  /* .theme2_header_avatar_name {
        display: none;
    } */
  .theme2_header_search_container:hover {
    position: absolute;
    width: 145px;
  }
  .theme2_header {
    font-size: 20px;
  }
}
.searchImag {
  display: none;
  float: right;
  margin-right: 10px;
  margin-top: 11px;
}
@media all and (max-width: 830px) {
  .searchBigImg {
    display: none;
  }
  .searchImag {
    display: block;
  }
}
@media all and (max-width: 446px) {
  .theme2_header_avatar_name {
    display: none;
  }
}
.account {
  position: absolute;
  margin-left: -80px;
  margin-top: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px rgb(0 0 0 / 5%);
  border-radius: 10px;
  width: 136px;
  height: 107px;
  background-color: #fff;
  padding: 18px 15px;
  line-height: 19px;
  z-index: 2;
}

.admin_account {
  min-width: 215px !important;
  margin-left: -160px !important;
}

.profile,
.logout {
  font-size: 20px;
  cursor: pointer;
}
.accountClick {
  color: #73c7a9;
}
.openAccount {
  display: none;
}
.rectangle {
  height: 20px;
  width: 20px;
  position: absolute;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  border-top: 1px solid #c4c4c4;
  border-right: 1px solid #c4c4c4;
  margin-left: 12px;
  margin-top: 10px;
  z-index: 2;
  background-color: #fff;
}
.css-c4yxs9 {
  padding: 13.5px !important;
}
.css-1e6coao {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 10px rgb(0 0 0 / 10%);
  border-radius: 10px;
  width: 97% !important;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}
.css-q0py2k,
.css-ezgl1k {
  background-color: #fff;
}
.openPostion {
  width: 4vw;
  height: 5vh;
  border-radius: 4px;
  background-color: #73c7a9;
  color: white;
  align-items: center;
}
