.top_NSF_Border{
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.applicantApp{
    float: left;
}
.applicantNum{
    float: right;
}
.applicantDetail{
    margin-top: 30px;
}
.staffAll{
    position: relative;
    top: 10px;
}