.ssu2_addItem {
  margin: 24px auto 0px;
  height: 22px;
  color: #73c7a9;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  cursor: pointer;
}
.ssu1_nearLocItem1 {
  border-radius: 60px;
  font-size: 15px;
  line-height: 18px;
  padding: 12px 14px;
  cursor: pointer;
  color: #ffff;
  background-color: #73c7a9;
  border: 1px solid #73c7a9;
  margin: 10px 0px;
}

.ssu1_nearLocItem1.selectedExp {
  color: #73c7a9; /* Change this to your desired hover color */
  background-color: #ffff;
}
.ssu2_text2 {
  margin: 60px auto 0px;
  height: auto;
  font-size: 26px;
  line-height: 29px;
  text-align: center;
}

.ssu2_text3_4 {
  margin: 60px auto 0px;
  min-height: 22px;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}

.ssu2_ins {
  width: 100%;
  min-height: 84px;
  margin-top: 36px;
  border: 1px solid grey;
}

.ssu2_exp {
  width: 100%;
  max-width: 620px;
  height: fit-content;
  margin: 36px auto 0px;
}

.ssu2_otherItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}

.ssu2_otherExp {
  max-width: 600px;
  width: 90%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  font-size: 16px;
  height: 48px;
  padding: 12px 10px;
}

/* .ssu2_otherExp_item {
    margin-left: 15px;
} */

@media all and (max-width: 930px) {
  .ssu2_otherItem {
    width: 38%;
    display: block;
    margin-left: -15px;
  }
  .ssu2_otherExp_item {
    margin-left: 0px;
  }
}

@media all and (max-width: 768px) {
  .ssu2_exp {
    width: 50%;
    min-width: 250px;
    margin-top: 24px;
  }
  .ssu2_otherItem {
    width: 50%;
    min-width: 250px;
    display: block;
  }
}
@media (min-width: 320px) and (max-width: 1024px) {
  .ssu2_otherItem {
    margin-left: 0;
  }
  .ssu2_otherItem button {
    width: 100%;
  }
}
