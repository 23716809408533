/**********************
****** DASHBOARD ******
**********************/
.admin_dash_part1 {
  margin: 0px;
}

.admin_datefilter_container {
  width: 100%;
  max-width: 240px;
  height: 48px;
  float: left;
  position: relative;
  padding: 12px 25px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  font-size: 14px;
  line-height: 22px;
}

.admin_datefilter_img {
  width: 16px;
  height: 16px;
  margin-right: 16px;
  float: left;
}

.admin_datefilter_btn {
  width: 10px;
  height: 16px;
  float: right;
  cursor: pointer;
}

.admin_dash_datePicker {
  opacity: 0;
  margin-top: -30px;
}

.admin_dash_item_container {
  margin: 0px;
  min-height: 88px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 32px;
}
/* new css  */
.admin_dash_item_container {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.admin_dash_item {
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 51px;
}

.admin_dash_item_img {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
}

.admin_dash_item_hr {
  margin: 0 10px;
  border: 0.5px solid #ccc;
  height: 50px;
}

.admin_dash_item_right {
  margin-left: 230px;
  padding: 42px;
}

.item_description {
  font-size: 12px;
  color: #333333;
  opacity: 0.7;
}

.admin_dash_item {
  border-width: 0px;
  cursor: default !important;
  background-color: white;
}

.admin_dash_item_img {
}

.admin_dash_item_right {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
}

.admin_dash_item_hr {
  width: 60%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 17%;
  display: none;
}

@media all and (max-width: 575px) {
  .admin_dash_item_right {
    border-left: 0px solid rgba(0, 0, 0, 0.1);
  }

  .admin_dash_item_hr {
    display: block;
  }

  .admin_dash_item_img {
    width: 70px;
  }
}

/*************************
** MANAGE ADMINISTRATOR **
**************************/
.admin_mngAdmin_nameFilter {
  width: calc(100% - 450px);
  min-height: 32px;
  border-radius: 80px;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
  /* margin-bottom: 18px; */
  font-size: 17px;
  padding: 18px 24px;
  cursor: default !important;
  border-width: 0px;
}

@media (max-width: 640px) {
  .admin_mngAdmin_nameFilter {
    font-size: 15px;
    padding: 13px 22px;
  }
}

.admin_mngAdmin_nameFilter_span1 {
  width: 120px;
  float: left;
  height: 16px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.admin_mngAdmin_nameFilter_input {
  width: calc(100% - 180px);
  height: 16px;
  border-width: 0px;
}

.admin_mngAdmin_roleFilter {
  min-height: 32px;
  background-color: white;
  font-size: 15px;
  border-width: 0px;
  border-radius: 80px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  /* margin-bottom: 10px; */
  padding: 13px 24px;
}

.add_admin_inputs .checkbox_input {
  width: 15px;
  height: 15px;
  border-color: black;
  border-width: 2px;
}

.checkbox_input_parent {
  position: absolute;
  top: 31px;
  left: 25px;
}

.checkbox_input_text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 12px;
  display: flex;
  align-items: center;
  color: #000000;
}

.add_admin_inputs input {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  width: 92%;
  height: 50px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  padding-left: 12px;
}

.add_admin_inputs input::placeholder {
  color: rgba(51, 51, 51, 0.5);
}

.add_admin_inputs .add_admin_btn {
  background: #73c7a9;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-transform: uppercase;
  width: 92%;
  height: 50px;
  border: none;
  text-align: center;
  color: #ffffff;
}

.add_admin_inputs .cancel_admin_btn {
  background: #333333 !important;
  color: #fff;
}

.add_admin_inputs input:focus {
  border-color: rgba(0, 0, 0, 0.2) !important;
  border-width: 1px !important;
}

.hide_scrollbar::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.admin_mngAdmin_addAdmin {
  width: 220px;
  background-color: #73c7a9;
  color: white;
  font-size: 15px;
  border-width: 0px;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 13px;
  }
}

.admin_mngAdmin_addAdmin a {
  text-decoration: none;
}

.add_admin_heading {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 31px;
  color: #333333;
}

@media all and (max-width: 1150px) {
  .admin_mngAdmin_nameFilter {
    width: 100%;
    margin-right: 0px;
  }

  .admin_mngAdmin_roleFilter {
    width: calc(50% - 15px);
  }

  .admin_mngAdmin_addAdmin {
    width: 50%;
  }
}

@media all and (max-width: 565px) {
  .admin_mngAdmin_roleFilter {
    width: 100%;
  }

  .admin_mngAdmin_addAdmin {
    width: 100%;
    margin-left: 0px;
  }

  .admin_mngAdmin_nameFilter_span1 {
    width: 80px;
  }

  .admin_mngAdmin_nameFilter_input {
    width: calc(100% - 140px);
  }
}

/******************************
********* MANAGE STAFF ********
******************************/
.admin_mngStaff_filter {
  margin-left: 15px;
}

@media all and (max-width: 1150px) {
  .admin_mngStaff_filter {
    width: 50%;
  }
}

@media all and (max-width: 565px) {
  .admin_mngStaff_filter {
    margin-left: 0px;
    width: 100%;
  }
}

/*************************
******* PAGINATION *******
**************************/
.page_container {
  width: fit-content;
  height: 28px;
  padding: 0px;
}

.page_arrow {
  width: 80px;
  height: 28px;
  background-color: white;
  color: #73c7a9;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  float: left;
  font-size: 13px;
  border-width: 0px;
  stroke: #73c7a9;
}

.page_next {
  margin-left: 8px;
}

.page_disable {
  cursor: not-allowed !important;
  background-color: rgba(0, 0, 0, 0);
  color: rgba(51, 51, 51, 0.5);
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0);
  stroke: rgba(51, 51, 51, 0.3);
}

.page_num {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #73c7a9;
  font-size: 13px;
  border-width: 0px;
  background-color: rgba(0, 0, 0, 0);
  margin-left: 8px;
  float: left;
}

.page_selected {
  background-color: #73c7a9;
  color: white;
}

.page_collapse {
  display: inline;
}

@media all and (max-width: 565px) {
  .page_arrow {
    width: 28px;
    border-radius: 50%;
  }

  .page_num {
    margin-left: 3px;
  }

  .page_collapse {
    display: none;
  }
}

/*************************
****** MANAGE STAFF ******
**************************/
.admin_table {
  border-collapse: collapse;
}

.admin_mngStaff_row:first-child,
.admin_mngStaff_td:first-child {
  border-top-left-radius: 60px;
  font-size: 17px;
}

.admin_mngStaff_row:first-child,
.admin_mngStaff_td:last-child {
  border-top-right-radius: 60px;
  font-size: 17px;
}

.admin_mngStaff_row:last-child,
.admin_mngStaff_td:first-child {
  border-bottom-left-radius: 60px;
  font-size: 17px;
}

.admin_mngStaff_row:last-child,
.admin_mngStaff_td:last-child {
  border-bottom-right-radius: 60px;
  font-size: 17px;
}

.admin_mngStaff_row {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 70px;
}

.admin_mngStaff_td {
  border-width: 6px;
  font-family: "Avenir LT Stds";
  font-size: 17px;
  line-height: 25px;
  font-weight: 400;
}
