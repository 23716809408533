/* .t2_subHeader {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
} */
.t2_subHeader {
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 12px, 12px, 0px, 0px;
  /* flex-direction: column; */
  background-color: #ffff;
  padding: 0px 0px 0px 0px;
  border-radius: 12px 12px 0px 0px;
  /* padding: 0 20px; */
  /* box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05); */
}

.t2_subHeader_1 {
  width: 100%;
  margin-bottom: 30px;
  display: none;
}

.t2_subHeader_2 {
  width: 100%;
  margin-bottom: 30px;
  display: none;
}

.t2_subHeader_3 {
  width: 100%;
  margin-bottom: 30px;
  height: 40px;
  display: none;
}

.t2_dropdown {
  right: 0px;
  margin-top: -65px;
}

.t2_subHeader_item {
  width: 300px;
  height: auto;
  /* border-radius: 80px; */
  font-size: 20px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  padding: 21px 15px;
  /* background: white; */
  border-width: 0px;
  color: #333333;
  opacity: 70%;
}

.t2_subHeader_item_clicked {
  /* background: #73C7A9; */
  color: #73c7a9;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-bottom: 3px solid #73c7a9;
  width: 300px;
  height: auto;
}

.t2_header_drop_selected {
  background: #73c7a9;
  color: white;
}

.t2_detail_textbox {
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis !important;
  font-weight: 400;
  margin-top: 10px;
  min-height: 35px;
}

@media all and (max-width: 900px) {
  .t2_subHeader {
    display: none;
  }

  .t2_subHeader_1 {
    display: block;
  }
}

@media all and (max-width: 770px) {
  .t2_subHeader_1 {
    display: none;
  }

  .t2_subHeader_2 {
    display: block;
  }
}

@media all and (max-width: 510px) {
  .t2_subHeader_1 {
    display: none;
  }

  .t2_subHeader_2 {
    display: none;
  }

  .t2_subHeader_3 {
    display: block;
  }

  .t2_dropdown {
    margin-top: -24px;
  }
}
