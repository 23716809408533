.ssu4_body {
  width: 90%;
  max-width: 1522px;
  height: auto;
  margin: 70px auto 0px;
  padding-top: 1px;
}

.ssu4_text2 {
  width: 100%;
  height: auto;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 36px;
  color: #333333;
}

.ssu4_text3 {
  width: 100%;
  height: auto;
  font-size: 22px;
  line-height: 36px;
  margin-top: 40px;
  color: #333333;
}

.ssu4_confirm {
  width: 100%;
  height: auto;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  color: #333333;
  margin-top: 60px;
  text-align: center;
}

.ssu4_checkBox {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid #73c7a9;
  border-radius: 5px;
  margin-right: 30px;
  color: #73c7a9;
}

.ssu4_checkBox:checked {
  -webkit-appearance: checkbox;
  accent-color: #048759;
}

.ssu4_checkBox:not(:checked) {
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border: 2px solid #73c7a9;
  border-radius: 5px;
  margin-right: 30px;
  color: #73c7a9;
}

@media all and (max-width: 768px) {
  .ssu4_text3 {
    font-size: 22px;
    line-height: 24px;
  }
  .ssu4_checkBox {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #73c7a9;
    border-radius: 5px;
    margin-right: 20px;
    color: #73c7a9;
  }
}
