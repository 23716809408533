.ssu1_body {
  width: 80%;
  max-width: 900px;
  height: auto;
  margin: 58px auto 0px;
}

.ssu1_input {
  width: 80%;
  max-width: 900px;
  min-width: 250px;
  height: auto;
  margin: 20px auto 0px;
}
.ssu2_modal1_input {
  /* display: flex; */
  align-items: center;
  background-color: #fff;
  /* border: 1px solid #73C7A9; */
  border-radius: 10px;
  padding: 8px 0px;
  position: relative;
  width: 100%;
}
.transparent-background {
  background-color: transparent !important;
  /* Add other styling properties as needed */
}
.ssu2_modal1_input input {
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 2px #cccccc;
  outline: none;
  flex: 1 1;
  /* padding: 4px 0px 4px 45px; */
  font-size: 16px;
  height: 100%;
  box-sizing: border-box;
}

.input_left_icon {
  margin-right: 8px; /* Spacing between the icon and input */
}
.MuiFormControl-root {
  max-width: 900px !important;
}

.MuiSvgIcon-root {
  width: 24px !important;
  height: 24px !important;
}

.ssu1_locs {
  width: 77%;
  /* max-width: 600px;
    height: fit-content; */
  margin: 0 auto !important;
  /* position: relative; */
}
.row.ssu1_locs {
  width: 77%;
  /* max-width: 600px;
    height: fit-content; */
  margin: 0 auto !important;
  /* position: relative; */
}

.ssu1_locItem {
  width: 100%;
  min-height: 48px;
  height: auto;
  border-radius: 36px;
  margin: 0;
  font-size: 16px;
  line-height: 16px;
  float: left;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border: 1px solid #73c7a9;
  background: #73c7a9;
  color: white;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
}

.ssu1_txt2 {
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 10px;
  text-align: center;
  margin-top: 10px;
}

.ssu1_txt3 {
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 17px;
  text-align: center;
  margin-top: 12px;
}

.ssu1_input1 {
  width: 80%;
  max-width: 900px;
  height: auto;
  margin: 20px auto 0px;
}

.ssu1_txt4 {
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-top: 15px;
}

.ssu1_nearLocs {
  width: fit-content;
  max-width: 600px;
  height: fit-content;
  margin: 20px auto 0px !important;
  position: relative;
}

.ssu1_nearLocItem {
  background-color: white;
  color: #73c7a9;
}

@media all and (max-width: 1339px) {
  .ssu1_locs,
  .ssu1_nearLocs,
  .ssu2_exp {
    max-width: 610px;
  }
}

@media all and (max-width: 930px) {
  .ssu1_locs {
    width: 38%;
    min-width: 250px;
    margin-top: 20px;
  }

  .ssu1_locItem {
    margin-left: 0px;
    min-width: 250px;
    margin-bottom: 12px;
    padding: 0px 10px;
  }
  .ssu1_nearLocs {
    width: 38%;
    min-width: 250px;
    margin-top: 20px;
  }
}

@media all and (max-width: 768px) {
  .ssu1_input {
    margin-top: 20px;
  }

  .ssu1_txt2 {
    font-size: 16px;
    line-height: 10px;
    margin-top: 10px;
  }

  .ssu1_txt3 {
    font-family: "Avenir LT Std";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    margin-top: 12px;
  }

  .ssu1_input1 {
    min-width: 250px;
  }

  .ssu1_txt4 {
    font-size: 16px;
    line-height: 18px;
  }
}
@media all and (min-width: 320px) {
  .outer_container {
    padding: 0px 15px;
  }

  .main_container {
    padding: 30px 0px 40px !important;
  }
}
