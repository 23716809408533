/****************************
******** AVATAR CSS *********
****************************/

.t2_sp_avatar_container {
  width: 100%;
  min-height: 108px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 7.5px;
  padding: 19.5px 22px;
  position: relative;
  margin-right: 0px;
  margin-left: 0px;
}

.t2_sp_avatar_img {
  width: 80px;
  height: 80px;
  float: left;
  position: relative;
}

.t2_sp_avatar_badge {
  width: 30px;
  height: 30px;
  position: absolute;
  right: -5px;
  bottom: -5px;
  cursor: pointer;
}

.t2_sp_avtar_desc {
  margin-top: 12px;
  width: fit-content;
  float: left;
  margin-left: 50px;
}

.t2_sp_avatar_txt1 {
  font-weight: 400;
  font-size: 22px;
  line-height: 25.5px;
  float: left;
  max-width: 400px;
}

.t2_sp_avatar_txt2 {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
}

.t2_sp_avatar_logout {
  width: 110px;
  height: 35px;
  border: 1px solid #73c7a9;
  box-sizing: border-box;
  border-radius: 100px;
  margin-top: 27px;
  padding: 5px 26px;
  font-size: 16px;
  line-height: 20px;
  color: #73c7a9;
  float: right;
  cursor: pointer;
}

@media all and (max-width: 767px) {
  .t2_sp_avatar_txt1 {
    width: 60%;
  }
}

@media all and (max-width: 565px) {
  .t2_sp_title1 {
    margin-bottom: 35px;
  }

  .t2_sp_avatar_img {
    float: none;
    width: 80px;
    height: 80px;
    margin-left: calc(50%-46px);
  }

  .t2_sp_avtar_desc {
    margin-top: 12px;
    width: fit-content;
    float: none;
    margin-left: 0px;
  }
}

.t2_sp_title1 {
  margin-top: 48px;
  margin-bottom: 10px;
}

@media all and (max-width: 505px) {
  .t2_sp_title1 {
    margin-bottom: 40px;
  }
}

/****************************
******   JOB TYPE    ********
****************************/
/* .t2_sp_work {
    margin-top: 48px;
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    height: auto;
} */

.t2_sp_work {
  margin: 0px auto;
  width: 100%;
  height: 100%;
  font-size: 18px;
  line-height: 22px;
  height: auto;
  background-color: #ffffff;
  padding: 40px 40px;
  border-radius: 15px;
}
.t2_Admin_works {
  margin: 0px auto;
  font-size: 18px;
  line-height: 22px;
  height: auto;
  background-color: #ffffff;
  /* padding: 30px 30px; */
  border-radius: 15px;
}

@media (max-width: 640px) {
  .t2_sp_work {
    padding: 0px !important;
  }
}

.t2_sp_work_title {
  font-size: 25px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 25px;
  color: #333333;
}

.t2_sp_work_container {
  /* padding: 15px 15px !important; */
  /* width: 100%; */
  /* min-height: 90px; */
  /* gap: 30px; */
  width: 100%;
  /* max-width: 560px; */
  height: auto;
  display: flex;
  gap: 15px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px 20px 20px;
  /* margin-top: 18px; */
  /* margin-right: 0px; */
  /* margin-left: 0px; */
}

.t2_sp_work_item {
  width: auto;
  border: 1px solid #73c7a9;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  float: left;
  color: #73c7a9;
  padding: 8px 0px;
  font-size: 17px;
  line-height: 25px;
  background-color: white;
  height: 52px;
}

.ssu_datePickerBox {
  width: 100%;
  height: 52px;
}

.t2_sp_item_selected {
  background-color: #73c7a9 !important;
  color: white;
  /* padding: 10px; */
  /* position: relative; */
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.t2_sp_calendar {
  /* position: absolute; */
  /* top: 20px; */
  /* right: 13px; */
  width: 36px;
  height: 18px;
  cursor: pointer;
  fill: #67625b;
  stroke: #67625b;
}

.t2_sp_calendar_selected {
  fill: white;
  stroke: white;
}

@media all and (max-width: 665px) {
  .t2_sp_work_item {
    float: none;
    width: 60%;
    margin: 5px auto 0px;
  }
}

/****************************
******   LOCATIONS    *******
****************************/
.t2_sp_loc_selected {
  background-color: #73c7a9;
  color: white;
  fill: white;
}

.t2_sp_loc_img {
  margin-right: 15px;
}

.t2_sp_loc_btn {
  margin-left: 15px;
  cursor: pointer;
}

/****************************
****** NURSE LICENSES *******
****************************/
.t2_sp_nl_item {
  width: 100%;
  margin: 38px auto auto;
  padding: 20px 27px;
  height: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 17px 21px 21px;
  margin-top: 18px;
  margin-right: 0px;
  margin-left: 0px;
  background-color: #ffffff;
}

.t2_sp_licence_row {
  width: 100%;
  height: 66px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.t2_sp_licence_th {
  font-family: "Avenir LT Std";
  font-size: 17px;
  line-height: 16px;
  font-weight: 350;
}

.t2_sp_licence_td {
  font-family: "Avenir LT Std";
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.t2_sp_licence_accBtn {
  border-width: 0px;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  padding: 0px 0px 0px;
}

.t2_sp_hr {
  color: rgba(0, 0, 0, 0.1);
}

.t2_sp_crimianlBtn {
  width: 80%;
  height: 48px;
  margin: 0px auto;
  color: white;
  background: #73c7a9;
  border: 1px solid rgba(51, 51, 51, 0.1);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 80px;
  font-size: 16px;
  line-height: 20px;
}

/****************************
******   DRUG TEST    *******
****************************/
.t2_sp_editdrug_part1 {
  width: 95%;
  max-width: 900px;
  height: auto;
  margin: 30px auto 0px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
}

.t2_sp_editdrug_img {
  width: 50%;
  float: left;
}

.t2_sp_editdrug_btn {
  width: 100%;
  min-width: 200px;
  max-width: 300px;
  height: 48px;
  margin: auto auto;
  background: #ffffff;
  border: 1px solid #73c7a9;
  border-radius: 60px;
  color: #73c7a9;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}

@media all and (max-width: 767px) {
  .t2_sp_editdrug_img {
    width: 50%;
    float: none;
    margin: 0px auto 20px;
  }
}

/****************************
****** Certifications *******
****************************/
.t2_sp_cert_addBtn {
  color: #73c7a9;
  float: right;
  font-weight: 400;
  cursor: pointer;
}

/****************************
****** Criminal Number ******
****************************/

.t2_green_success {
  margin-top: 20px;
  width: 100%;
  height: 90px;
  background: rgba(0, 156, 222, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-size: 20px;
  line-height: 32px;
  padding: 29px;
}

/* Define CSS classes for different conditions */
.button-green {
  background-color: green;
  color: white;
}

.button-blue {
  background-color: #73c7a9;
  color: white;
}

.button-red {
  background-color: red;
  color: white;
}

.button-yellow {
  background-color: yellow;
  color: black;
}

.button-lightblue {
  background-color: lightblue;
  color: black;
}

.button-pink {
  background-color: pink;
  color: black;
}

.button-disabled {
  cursor: not-allowed;
}
.no-data-found {
  text-align: center;
  margin: auto;
  padding: 271px;
  font-size: 22px;
}
