/************* DRAG & DROP AREA **************/
.drag-area {
  width: 100%;
  height: auto;
  border-radius: 20px;
  /* border: 2px dashed #73C7A9; */
  margin-top: 24px;
  /* padding-bottom: 12px; */
}
/* .drag-area.active {
    border: 2px solid black;
} */
.drag-area .icon {
  font-size: 60px;
  color: #73c7a9;
}
.drag-area header {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-top: 30px;
}
.drag-area p {
  width: 100%;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: #333333;
  margin-top: 12px;
}
.drag-area button {
  width: 97%;
  min-width: 230px;
  height: 48px;
  margin: 0px auto;
  background: #ffffff;
  border: 1px solid #73c7a9;
  border-radius: 60px;
  color: #73c7a9;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
}
.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 3px;
}
