.ssu_container {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  padding: 1px 0px 1px;
  font-family: "Avenir LT Std";
  font-style: normal;
  font-weight: normal;
  overflow: auto;
  overflow-y: auto;
  /* height: 100vh; */
  height: 100%;
}

.datePickerInput {
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  cursor: pointer;
}

.example-custom-input {
  background: url("../../assets//images/calendar.png") no-repeat right;
  background-size: 20px;
  width: 240px;
  height: 24px;
}

.ssu_datePickerContainer .react-datepicker-wrapper {
  display: flex;
  align-items: center;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
}

.datePickerInput input {
  border: none;
  outline: none;
  flex-grow: 1;
  padding: 4px;
  margin-left: 30px;
}

.datePickerIcon {
  cursor: pointer;
  position: absolute;
  left: 12px;
  width: 20px;
  height: 20px;
}

.ssu_body {
  width: 80%;
  max-width: 820px;
  height: auto;
  margin: 60px auto 0px;
}

/* .ssu_txt1 {
    width: 100%;
    height: auto;
    font-size: 36px;
    font-weight: normal;
    line-height: 42px;
    text-align: center;
    color: #333333;
} */

.ssu_txt1 {
  width: 100%;
  /* height: auto; */
  font-size: 36px;
  font-weight: normal;
  line-height: 42px;
  text-align: center;
  color: #333333;
  margin: 45px 0px 45px 0px;
}

.ssu_selBox1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 77%;
  margin: 0px;
  position: relative;
}

/* .ssu_sel1 {
    min-width: 150px;
    min-height: 60px;
    border-radius: 60px;
    margin-left: 4.8%;
    font-size: 15px;
    line-height: 18px;
    padding: 15px 40px;
    cursor: pointer;
    background-color: white;
    text-decoration: none;
    color:#73C7A9;
    border: 1px solid #73C7A9;
} */

.ssu_sel1 {
  border-radius: 60px;
  font-size: 15px;
  line-height: 18px;
  padding: 15px 0px;
  cursor: pointer;
  color: #73c7a9;
  border: 1px solid #73c7a9;
  margin-top: 10px;
}

.ssu_txt2 {
  width: 100%;
  height: auto;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #333333;
  margin-top: 20px;
}

.ssu_selBox2 {
  width: 77%;
  margin: 25px auto 0px;
  position: relative;
}

.ssu_sel2_1 {
  border-radius: 60px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 15px 0px;
  cursor: pointer;
  color: #73c7a9;
  border: 1px solid #73c7a9;
  margin: 10px 0px;
}

/* ds */

/* .ssu_selBox2 {
    width: 100%;
    margin: 36px auto 0px;
    display: flex;
    flex-direction: column;
} */

/* .ssu_sel2_2 {
    width: 53%;
    height: 60px;
    border-radius: 60px;
    margin-left: 7%;
    font-size: 16px;
    line-height: 18px;
    float: left;
    cursor: pointer;
    text-align: center;
    padding-top: 19px;
    text-decoration: none;
    color:#73C7A9;
    border: 1px solid #73C7A9;
} */

.ssu_sel2_2 {
  border-radius: 60px;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  padding: 15px 0px;
  cursor: pointer;
  color: #73c7a9;
  border: 1px solid #73c7a9;
  margin: 10px 0px;
}

.ssu_sel_selected {
  background: #73c7a9;
  color: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ssu_bottom {
  width: 100%;
  /* max-width: 820px; */
  height: auto;
  margin: 1px auto;
  padding-top: 1px;
}

.ssu_hr {
  margin: 10px 0px 0px;
}

.ssu_button_outline {
  background-color: #ffffff !important;
  color: #73c7a9 !important;
  border: 1px solid #73c7a9;
}

.ssu_button {
  width: 67%;
  /* max-width: 320px;
    min-width: 200px; */
  height: 48px;
  margin: 10px auto 10px;
  background-color: #73c7a9;
  border-radius: 60px;
  text-align: center;
  font-size: 16px;
  line-height: 16px;
  padding-top: 16px;
  color: white;
  cursor: pointer;
}

/*********** CUSTOM DATE PICKER *************/
.ssu_datePickerBox {
  width: 100%;
  /* max-width: 500px;
    min-width: 200px; */
  /* margin: 33px auto 0px; */
  height: auto;
  margin: 0 auto;
}

.ssu_datePicker {
  font-size: 15px;
  line-height: 17px;
  border-width: 0px;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.ssu_datePicker::placeholder {
  color: #73c7a9;
}

.ssu_sel2_1.ssu_sel_selected {
  background-color: #73c7a9;
  /* Your desired background color */
}

.ssu_datePicker.ssu_sel_selected {
  background-color: #73c7a9;
  /* Your desired background color for the datepicker */
  border-radius: 8px;
  /* Rounded corners when selected */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

/* .modal3_selectBox{
    width: 48%;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 100px;
} */

.MuiFormControl-root {
  width: 100%;
  min-height: 48px;
  border-radius: 60px;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  padding: 15px 18px !important;
}

.MuiInputBase-input {
  font-size: 15px !important;
  line-height: 18px !important;
}

.MuiInput-underline:before,
.MuiInput-underline:after {
  border-width: 0px !important;
}

@media all and (max-width: 1280px) {
  .ssu_txt1 {
    font-size: 30px;
  }
}

@media all and (max-width: 768px) {
  .ssu_body {
    margin: 36px auto 0px;
  }

  .ssu_txt1 {
    font-size: 24px;
    line-height: 27px;
  }

  .ssu_selBox1 {
    display: flex;
    width: 60%;
    min-width: 150px;
    margin-top: 0px;
    height: 150px;
  }

  .ssu_sel1 {
    float: left;
    width: 100%;
    margin-left: 0px;
    border-radius: 60px;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
  }

  .ssu_txt2 {
    width: 100%;
    height: auto;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: #333333;
    margin-top: 20px;
  }

  .ssu_selBox2 {
    width: 60%;
    min-width: 150px;
    margin-top: 18px;
    position: relative;
  }

  .ssu_sel2_1 {
    width: 100%;
    /* position: relative; */
  }

  .ssu_sel2_2 {
    width: 100%;
    margin-left: 0%;
    margin-top: 6px;
  }

  .ssu_sel_selected {
    background: #73c7a9;
    color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .ssu_hr {
    margin-top: 10px;
  }

  .MuiFormControl-root {
    padding: 15px 12px !important;
  }
}

@media all and (max-width: 560px) {
  .ssu_txt1 {
    font-size: 22px;
    line-height: 24px;
  }
}

@media all and (max-width: 560px) {
  .ssu_selBox2 {
    width: 100%;
  }

  .ssu_selBox1 {
    width: 100%;
    margin-top: 0;
    display: flex;
    height: 100px;
  }
}
