h3 {
  padding: 10px;
}
.dashboard_main {
  padding: 15px 25px;
}
.Job_Application_Container {
  margin-top: 33px;
  display: flex;
  gap: 30px;
  padding-bottom: 60px;
  /* justify-content: space-between; */
}
@media (max-width: 801px) {
  .dashboard_main {
    display: grid;
    width: 100%;
    margin-top: 50px !important;
  }
  .theme2_body {
    min-height: 0 !important;
  }
  .date_header {
    display: block !important;
    position: absolute !important;
    width: 100% !important;
    right: -190px !important;
    top: 90px !important;
    font-style: normal;
    font-size: 20px !important;
    color: #333333;
    width: 200px;
  }
  .staff_contract {
    width: 100% !important;
  }
  .staff_contract h3 {
    padding: 0;
  }
  .staff_contract .card {
    margin-top: 10px;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .staff_hiring {
    width: 100% !important;
  }
  .staff_hiring hr {
    margin-left: 5px !important;
  }
  .card_body_3 hr {
    margin-left: 5px !important;
  }
}
.staff_contract {
  /* width: 50%;
  height: 55vh !important;
  border: 5px solid white;
  background-color: white;
  border-radius: 6px;
  margin-top: 10px;
  margin-right: 10px; */
}
.staff_contract h3 {
  margin-bottom: 30px;
}
.first_row {
  border-radius: 6px;
}
.card {
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
}

.card_body_1 {
  width: 325px;
  height: auto;
  align-items: center;
  border-radius: 60px;
}
.card_body_1 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 16px;
  line-height: 19px;
}
.card_body_2 {
  height: 135px;
  margin-top: 50px;
  align-items: center;
}
.card_body_2 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 16px;
  line-height: 19px;
}

.card_body_3 {
  height: 135px;
  margin-top: 50px;
  align-items: center;
  margin-bottom: 10px;
}
.card_body_3 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 16px;
  line-height: 19px;
}
.card_body_4 {
  display: flex;
  height: auto;
  width: 20vw;
  /* padding: 20px; */
  justify-content: space-between;
  /* margin-top: 50px; */
  align-items: center;
  /* margin-bottom: 10px; */
}
.card_body_4 p {
  color: rgba(51, 51, 51, 0.7);
  font-size: 20px;
  line-height: 25px;
}
.bottom_card {
  margin: 30px 10px 0 10px;
}
.bottom_card hr {
  width: 1px;
  height: 60px;
  margin-left: 30px;
  margin-top: -2px;
  display: inline-block;
  border: 1px rgba(0, 0, 0, 0.1);
}
.col_logo {
  float: left;
  margin-left: 40px;
}
.date_header {
  /* width: 100%;
    height: 60px;
    margin: auto ;
    margin-bottom: -25px;
   position: absolute;
    left: 900px; */
  /* position: absolute; */

  right: 0;
  top: 120px;

  font-style: normal;

  font-size: 20px;
  line-height: 0px;

  color: #333333;
}

.date_header_staff {
  position: initial !important;
  margin-left: 0px !important;
}

.staff_hiring {
  /* width: 50%; */
  /* height: 55vh !important; */
  /* background-color: white; */
  /* border-radius: 6px; */
  /* margin-top: 10px; */
  /* margin-left: 10px; */
}
