.recentjob {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.imgRounded {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.AllText {
  flex: 1;
  margin-left: 2px;
  text-align: left;
  margin-top: 19px;
}

.companyName {
  margin: 0;
  width: 14vw;
}

.Address {
  width: 100%;
  margin: 0;
  font-weight: 600;
}
.clock {
  float: right;
  margin-top: 15px;
}
.ShowAllButton {
  position: relative;
  top: 20px;
  align-items: center;
  align-items: baseline;
  background-color: #73c7a9;
  border: 1px solid #73c7a9;
  border-radius: 60px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  height: 48px;
  justify-content: center;
  line-height: 16px;
  margin: 0 auto;
  min-width: 200px;
  padding-top: 16px;
  text-align: center;
}
