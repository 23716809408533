.PostLocation {
  font-size: 18px !important;
  font-weight: 600;
}
.settings {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  padding: 15px 20px;
  text-align: center;
  cursor: pointer;
}
.medicalSettings {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.setting {
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
  border-radius: 100px;
  padding: 15px 20px;
  text-align: left;
  cursor: pointer;
}
.time {
  display: flex;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 15px rgb(0 0 0 / 5%);
  border-radius: 100px;
  padding: 1px 1px 1px 20px;
  width: fit-content;
  margin-left: 25px;
}
.timeDiv {
  display: flex;
}
.timeText {
  margin-top: 8px;
}
.AM,
.PM {
  background: #73c7a9;
  border-radius: 100px;
  border: 0px;
  padding: 10px;
  color: #fff;
  margin-left: 9vmax;
}
.days {
  width: 100%;
  border: 0px;
}
.locationSelect {
  width: 97%;
  border: 0px;
}
@media all and (max-width: 768px) {
  .JobCancel {
    left: -8px;
  }
}
.background {
  background: #73c7a9;
  color: #fff;
}
