@tailwind base;
@tailwind components;
@tailwind utilities;

.input_left_post_icon {
  height: 19px;
  left: 23px;
  top: 20px;
  position: absolute;
  width: 22px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: #73c7a9 !important;
  color: white;
  font-weight: bold;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #73c7a9 !important;
  color: #fff;
}
.custom-dropdown-container {
  position: relative;
  display: inline-block;
  width: 200px;
}

.custom-dropdown-trigger {
  background-color: #f0f0f0;
  padding: 10px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: black;
}

.custom-dropdown-contentnew {
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  width: 100%;

  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 5px;
  color: black;
}

.custom-dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.custom-dropdown-item.hover {
  background-color: #e0e0e0;
}

.custom-dropdown-content {
  min-width: 170px;
  z-index: 20;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  background-color: white;
}
.custom-dropdown-contentnew div:hover {
  background-color: #73c7a9;
}

.custom-dropdown-content div:hover {
  background-color: #73c7a9;
}

.ssu2_text3_4 {
  margin: 30px auto 10px;
}

.ssu_selBox1 {
  width: 100%;
}

.ssu2_otherItem {
  width: 100%;
}

.ssu_selBox1 {
  height: auto;
}
.react-time-picker__wrapper {
  display: flex;
  font-size: 20px;
  box-shadow: 0px 0px 5px #73c7a9;
  border-radius: 25px;
  padding: 5px 10px;
  width: 300px;
  justify-content: center;
  border: none;
}

.react-datepicker__input-container input {
  width: 800px;
}

.react-time-picker__inputGroup input,
.react-time-picker__inputGroup span,
.react-time-picker__inputGroup input:focus {
  font-size: 22px;
  border: none !important;
  box-shadow: none;
}
